(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.profile.controller:ProfileCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.user.profile')
    .controller('ProfileCtrl', ProfileCtrl);

  function ProfileCtrl($stateParams, $filter, actionButton, profile, ActionResendRegEmail, $mdToast, UserProfile, AclService) {
    var vm = this;
    vm.ctrlName = 'ProfileCtrl';
    vm.edit = false;
    vm.profile = profile;
    vm.isAdmin = canChangeUsername();

    actionButton.dial = true;

    actionButton.buttonImage = 'images/ic_more_vert_white_48px.svg';
    actionButton.actions = [
      {
        buttonImage: 'images/ic_edit_black_48px.svg',
        attrClass: '',
        tooltip: $filter('translate')('EDIT'),
        execute: enableEdit
      },
      {
        buttonImage: 'images/ic_send_black_48px.svg',
        attrClass: '',
        tooltip: $filter('translate')('RESEND_EMAIL'),
        execute: resendEmail
      }];

    vm.updateEmail = updateEmail;

    function resendEmail() {
      ActionResendRegEmail.save({email: vm.profile.email},
        function () {
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')('SUCCESSFUL_EMAIL_RESEND'))
              .position('bottom left')
              .hideDelay(3000)
          );
        }, function (error) {
          var message = null;
          if (error.status === 409) {
            message = 'ALREADY_REGISTERED_USER_AND_NOT_RESEND';
          } else {
            message = 'ERROR_SENDING_MAIL';
          }
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')(message))
              .position('bottom left')
              .hideDelay(3000)
          );
        });
    }

    function canChangeUsername() {
      return AclService.isAdmin() || AclService.can('TOWN_HALL', 'R');
    }

    function enableEdit() {
      vm.edit = true;
      actionButton.dial = false;
    }

    function updateEmail() {
      UserProfile.update({id: $stateParams.userId}, vm.profile,
        function () {
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')('SUCCESSFUL_EMAIL_CHANGE_SEND'))
              .position('bottom left')
              .hideDelay(3000)
          );
          vm.edit = false;
          actionButton.dial = true;
        }, function (error) {
          var message = 'ERROR_MODIFY_MAIL';
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')(message))
              .position('bottom left')
              .hideDelay(3000)
          );
        });
    }
  }
}());
